import React, {FC} from 'react';


const Head: FC = () => {
    return (
        <div>
            <h1>Головна</h1>
            <img src='https://mdbootstrap.com/img/new/slides/041.webp' className='img-fluid rounded-pill' alt='...' />

        </div>
    );
};

export default Head;